class SpanEvents {
    constructor() {
        this.events = [];
    }
    add(name, time) {
        this.events.push({ name, time });
    }
    toJson(clock) {
        return this.events.map(({ name, time }) => ({ name, timeUnixNano: clock.toUnixTimestampNanoseconds(time) }));
    }
}

export { SpanEvents };
