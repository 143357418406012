// a processor that buffers spans in memory until the client has started
// not sure if this would need to be platform specific — will we ever care about
// persisting spans if 'start' is never called?
class BufferingProcessor {
    constructor() {
        this.spans = [];
    }
    add(span) {
        this.spans.push(span);
    }
}

export { BufferingProcessor };
